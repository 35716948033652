// Libs
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

const BottomSheetContainer = ({ open, setOpen, children }) => {
  const onDismiss = () => setOpen(false);

  return (
    <BottomSheet
      onDismiss={onDismiss}
      snapPoints={({ _, maxHeight }) => [maxHeight / 1.3, maxHeight / 1.3]}
      open={open}
    >
      {children}
    </BottomSheet>
  );
};

export default BottomSheetContainer;
