import {
  AmericanFootbal,
  AnimalDrumsticks,
  Reward,
  Star,
  Travel,
  TravelVacationHolidayUmbrella,
  Users,
  WineGlass,
} from "../../../../../assets/Icons";
import { IconCard } from "../../../../../common/iconCard";
import styles from "./styles.module.scss";

export const DATA = {
  "Vacation": Travel,
  "Wedding": WineGlass,
  "Holiday": TravelVacationHolidayUmbrella,
  "Sporting event": AmericanFootbal,
  "Reunion": Users,
  "Birthday": Reward,
  "Other": Star,
  "No events anytime soon": AnimalDrumsticks,
};

export const FutureEvent = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>Do you have an important occasion coming up?</h2>
      <p className={styles.hint}>Having an event you're excited about can be an incredible motivator</p>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([value, Icon]) => (
          <IconCard
            onClick={() => handleNextStep(value + 1)}
            img={<Icon />}
            title={value}
            key={value}
          />
        ))}
      </div>
    </div>
  );
};
