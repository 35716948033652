import eighteen from "../img/18-29.png";
import thirty from "../img/30-39.png";
import fourty from "../img/40-49.png";
import fifty from "../img/50+.png";

export const DATA = [
  { value: "18-29", image: eighteen },
  { value: "30-39", image: thirty },
  { value: "40-49", image: fourty },
  { value: "50+", image: fifty },
];
