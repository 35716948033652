import { IconCard } from "../../../../../common/iconCard";
import { Devices, Night, Paint, Rest } from "./icons";
import styles from "./styles.module.scss";

const DATA = {
  "9 to 5": Devices,
  "Night shifts": Night,
  "Flexible hours": Paint,
  "I'm retired/not working right now": Rest,
};

export const WorkLife = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>How does your work life look like?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([value, Icon]) => (
          <IconCard
            onClick={() => handleNextStep(value + 1)}
            img={<Icon />}
            title={value}
            key={value}
          />
        ))}
      </div>
    </div>
  );
};
