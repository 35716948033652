import {
  CoffeColdPressOne,
  CoffeeCupHot,
  CoffeeMachineOne,
  WaterBottle,
} from "../../../../../assets/Icons";
import { IconCard } from "../../../../../common/iconCard";
import styles from "./styles.module.scss";

const DATA = {
  "I only drink coffee or tea": CoffeeCupHot,
  "About 2 glasses (16 oz)": WaterBottle,
  "2 to 6 glasses (16-48 oz)": CoffeColdPressOne,
  "More than 6 glasses": CoffeeMachineOne,
};

export const WaterIntake = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>What your daily water intake?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([value, Icon]) => (
          <IconCard
            onClick={() => handleNextStep(value + 1)}
            img={<Icon />}
            title={value}
            key={value}
          />
        ))}
      </div>
    </div>
  );
};
