// Libs
import * as yup from "yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";

// Components
import ForgetPasswordModal from "./ForgetPasswordModal";
import SubmitButton from "../../common/SubmitButton";

// API
import authService from "../../api/services/authService";

import classes from "./styles.module.scss";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("E-mail is required!")
      .matches(
        /[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+/g,
        "Incorrect email. Please try again"
      ),
    pin: yup.string().required("pin is required field!"),
  })
  .required();

const AuthPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (authService.getCurrentUser()) {
      navigate("/profile", { replace: true });
    }
  }, [navigate]);

  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email, pin }) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const { status } = await authService.emailAndPinSignIn(
      email,
      pin,
      setError
    );

    if (status === 200) {
      navigate("/profile");
    }
    setIsLoading(false);
  };

  const toogleModal = () => setModalOpen((bool) => !bool);

  return (
    <div className={classes.container}>
      <h1>Login</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <input
          placeholder="Email"
          type="email"
          {...register("email")}
          className={errors.email && classes.errorInput}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <p className={classes.errorMessage}>{message}</p>
          )}
        />
        <input
          placeholder="Pin"
          type="text"
          {...register("pin")}
          className={errors.pin && classes.errorInput}
        />
        <ErrorMessage
          errors={errors}
          name="pin"
          render={({ message }) => (
            <p className={classes.errorMessage}>{message}</p>
          )}
        />
        <SubmitButton
          type="submit"
          title="Log in"
          isLoading={isLoading}
          variant="greenBackground"
        />
      </form>
      <button className={classes.forgotPassword} onClick={toogleModal}>
        Forgot pin?
      </button>
      <div className={classes.description}>
        By submitting you agree to our <a href="##">Privacy Policy</a>. Your
        personal information is safe with us.
      </div>
      {modalOpen && <ForgetPasswordModal toogleModal={toogleModal} />}
    </div>
  );
};

export default AuthPage;
