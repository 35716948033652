import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Back } from "../../assets/Icons";

export const OnboardingLayout = ({
  children,
  headerContent,
  onNavigateBack,
  hideNavigateBack = false,
  hideNavigation = false,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const ms = localStorage.getItem("ms");
    if (!ms) {
      localStorage.setItem("ms", "us");
    }
  }, []);

  const navigate = useNavigate();
  const toggleBurger = () => {
    setOpen(!open);
  };
  const handleNavigateBack = () => {
    if (onNavigateBack) {
      onNavigateBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <div className={styles.headerItem}>
          {!hideNavigateBack && (
            <button className={styles.backBtn} onClick={handleNavigateBack}>
              <Back />
            </button>
          )}
        </div>
        {headerContent || <p className={styles.logo}>EasyFit</p>}
        {hideNavigation ? (
          <div className={styles.headerItem}></div>
        ) : (
          <div
            className={
              open ? `${styles.burger} ${styles.open}` : `${styles.burger}`
            }
            onClick={toggleBurger}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
        <nav
          className={
            open
              ? `${styles.navigation}  ${styles.open}`
              : `${styles.navigation} ${styles.close}`
          }
        >
          <ul>
            <li>
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/subscription-policy">Subscription Policy</Link>
            </li>
          </ul>
        </nav>
      </header>
      <main className={styles.main}>{children}</main>
    </div>
  );
};
