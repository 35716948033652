import { axiosInstance } from "../index";
import authService from "./authService";

const getSubscriptionActive = async () => {
  return axiosInstance
    .get("me/subscriptions/active", {
      headers: {
        Authorization: authService.getCurrentUser(),
      }
    })
    .then((response) => {
      return response.data.items;
    })
    .catch((error) => {
      console.log(error)
    });
};

const cancelSubscriptionById = async (id) => {
  console.log(authService.getCurrentUser())
  return axiosInstance
    .post(`me/subscriptions/${id}/cancel`, {}, {
      headers: {
        Authorization: authService.getCurrentUser(),
      }
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(error)
    });
};

const subscriptionPlanService = {
  getSubscriptionActive,
  cancelSubscriptionById,
};

export default subscriptionPlanService;
