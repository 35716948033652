import { useParams } from "react-router";
import { Steps } from "../data/steps";
import styles from "./styles.module.scss";
import { useSearchParams } from "react-router-dom";

export const ProgressBar = () => {
  const { title } = useParams();
  const [searchParams] = useSearchParams();
  const currentStep = searchParams.get("q");
  const currentGroupIndex = Steps.findIndex((step) => step.title === title);

  const GroupTitlesMap = {
    activity: "Activity",
    profile: "My Profile",
    lifestyle: "Lifestyle & Habits",
    nutrition: "Nutrition",
    almost_done: "Almost Done",
  };

  const stepGroupProgress = () => {
    return ((+currentStep - 1) / Steps[currentGroupIndex].steps.length) * 100;
  };

  return (
    <div className={styles.progressBarContainer}>
      <h3>{GroupTitlesMap[title]}</h3>
      <div className={styles.progressBars}>
        {Steps.map((step, index) => (
          <div key={step.title} className={styles.progressBarItem}>
            {currentGroupIndex >= index && (
              <div
                style={{
                  width: `${
                    currentGroupIndex === index ? stepGroupProgress() : 100
                  }%`,
                }}
                className={styles.fill}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
