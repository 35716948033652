import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthPage } from "./routes/auth";
import { ProfilePage } from "./routes/profile";
import ProtectedRoutes from "./api/ProtectedRoutes";
import DayExercises from "./routes/dayExercises";
import SomethingWentWrong from "./routes/somethingWentWrong/SomethingWentWrong";
import { useEffect } from "react";
import { LandingPage } from "./routes/landing";
import { PrivacyPolicy, SubscriptionPolicy, TermsOfUse } from "./routes/terms";
import {
  CreatingPlan,
  CurrentStep,
  OnboardingStepper,
  Prediction,
} from "./routes/onboarding";
import { DATA } from "./routes/onboarding/stepper/questions/start/data";
import { usePreloadImages } from "./hooks/usePreloadImages";

function App() {
  usePreloadImages(DATA.map((item) => item.image));

  const removeLoader = () => {
    const loader = document.querySelector("#loader");
    if (loader) {
      loader.style.display = "none";
    }
  };
  useEffect(() => {
    if (document.readyState === "complete") {
      removeLoader();
    } else {
      window.addEventListener("load", removeLoader);
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthPage />} path="/auth" />
          <Route element={<LandingPage />} path="/" />
          <Route element={<TermsOfUse />} path="/terms-of-use" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<SubscriptionPolicy />} path="/subscription-policy" />
          <Route element={<OnboardingStepper />} path="/onboarding">
            <Route element={<CurrentStep />} path="/onboarding/:title" />
          </Route>
          <Route element={<Prediction />} path="/predicted" />
          <Route element={<CreatingPlan />} path="/creating-plan" />

          <Route
            element={<SomethingWentWrong />}
            path="/something-went-wrong"
          />
          <Route element={<ProtectedRoutes />}>
            <Route element={<ProfilePage />} path="/profile" />
            <Route
              element={<SomethingWentWrong />}
              path="/something-went-wrong"
            />
            <Route element={<DayExercises />} path="day-exercises/:id" />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
