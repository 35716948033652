import { Scale } from "../../../../../../assets/Icons";
import styles from "./styles.module.scss";

export const GoalHint = () => {
  return (
    <div className={styles.card}>
      <Scale />
      <h6>Go for it: you can lose up to 26% of your weight.</h6>
      <p>
        Reducing excess weight decreases the risk of heart disease, helps
        alleviate straining of joints and improved quality of sleep
      </p>
    </div>
  );
};
