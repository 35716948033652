import { useEffect } from "react";
import { openPayPal } from "../../redux/slices/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";

const CheckoutModal = ({ handleCloseModal }) => {
  const dispatch = useDispatch();

  const selectedSubscriptionPlan = useSelector((state) =>
    state.subscription.plans.find((plan) => plan.active === true)
  );

  const isLoading = useSelector((state) => state.subscription.isLoading);
  const paymentPageUri = useSelector(
    (state) => state.subscription.paymentPageUri
  );
  useEffect(() => {
    dispatch(openPayPal(selectedSubscriptionPlan.id));
  }, [dispatch, selectedSubscriptionPlan.id]);

  return (
    <div className="ppg-checkout-modal">
      <div
        className="ppg-btn-close"
        onClick={() => {
          handleCloseModal();
        }}
      >
        <img
          src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDQzLjYgMzg3LjEgMzEyLjQgMjU1LjRsMTMxLjUtMTMwYzUuNC01LjQgNS40LTE0LjIgMC0xOS42bC0zNy40LTM3LjZjLTIuNi0yLjYtNi4xLTQtOS44LTQtMy43IDAtNy4yIDEuNS05LjggNEwyNTYgMTk3LjggMTI0LjkgNjguM2MtMi42LTIuNi02LjEtNC05LjgtNC0zLjcgMC03LjIgMS41LTkuOCA0TDY4IDEwNS45Yy01LjQgNS40LTUuNCAxNC4yIDAgMTkuNmwxMzEuNSAxMzBMNjguNCAzODcuMWMtMi42IDIuNi00LjEgNi4xLTQuMSA5LjggMCAzLjcgMS40IDcuMiA0LjEgOS44bDM3LjQgMzcuNmMyLjcgMi43IDYuMiA0LjEgOS44IDQuMSAzLjUgMCA3LjEtMS4zIDkuOC00LjFMMjU2IDMxMy4xbDEzMC43IDEzMS4xYzIuNyAyLjcgNi4yIDQuMSA5LjggNC4xIDMuNSAwIDcuMS0xLjMgOS44LTQuMWwzNy40LTM3LjZjMi42LTIuNiA0LjEtNi4xIDQuMS05LjgtLjEtMy42LTEuNi03LjEtNC4yLTkuN3oiIGZpbGw9IiNmZmZmZmYiIGNsYXNzPSJmaWxsLTAwMDAwMCI+PC9wYXRoPjwvc3ZnPg=="
          alt="close"
        />
      </div>
      {isLoading ? (
        <div className="ppg-loader" />
      ) : (
        <iframe
          id="ppg-iframe"
          title="checkout"
          className="ppg-iframe"
          src={paymentPageUri || ""}
        />
      )}
    </div>
  );
};

export default CheckoutModal;
