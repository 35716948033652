import { useEffect, useState } from "react";

export const useMeasureSystem = () => {
  const msLs = localStorage.getItem("ms");
  const [ms, setMs] = useState(msLs);

  useEffect(() => {
    if (!msLs) {
      localStorage.setItem("ms", "us");
      setMs("us");
    }
  }, [msLs]);

  const changeMs = (value) => {
    setMs(value);
    localStorage.setItem("ms", value);
  };
  return {
    ms,
    changeMs,
  };
};
