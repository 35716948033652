import { Check, Warning } from "../../../../../../assets/Icons";
import styles from "./styles.module.scss";

const getBmiInfo = (index) => {
  let bmiType = "normal";

  if (index <= 18) {
    bmiType = "underweight";
  } else if (index >= 28 && index <= 70) {
    bmiType = "overweight";
  } else if (index > 70) {
    bmiType = "obese";
  }

  const BMIMap = {
    normal: {
      icon: <Check />,
      paragraph:
        "Your starting point is great! Your BMI will help us personalize a program for you",
    },
    underweight: {
      icon: <Warning color="#FF6A6A" />,
      paragraph:
        "You have some work to do but cudos for taking the first step! Your BMI will help us personalize a program for you",
    },
    overweight: {
      icon: <Warning />,
      paragraph:
        "You have some work to do but cudos for taking the first step! Your BMI will help us personalize a program for you",
    },
    obese: {
      icon: <Warning color="#FF6A6A" />,
      paragraph:
        "Let's lose a little weight together!  Your BMI will help us personalize a program for you",
    },
  };

  return { bmiInfo: BMIMap[bmiType], bmiType };
};

export const BMI = ({ bmi }) => {
  const { bmiInfo, bmiType } = getBmiInfo(bmi);
  return (
    <div className={`${styles.bmi} ${styles[bmiType]}`}>
      {bmiInfo.icon}
      <h6>
        Your BMI is {bmi} which is considered <strong>{bmiType}</strong>
      </h6>
      <p>{bmiInfo.paragraph}</p>
    </div>
  );
};
