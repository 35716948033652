import classes from "./styles.module.scss";
import close from "../assets/close.svg";

const Modal = ({ children, toogleModal }) => {
  return (
    <div className={classes.modal}>
      <div className={classes.modalWindow}>
        <div className={classes.closeModal} onClick={toogleModal}>
          <img src={close} alt="" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
