import {
  ChargeFour,
  ChargeThree,
  ChargeTwo,
  ChargeOne,
} from "../../../../../assets/Icons";
import { IconCard } from "../../../../../common/iconCard";
import styles from "./styles.module.scss";

const DATA = {
  "Alert and vibrant": ChargeOne,
  "A drop of energy in the afternoon": ChargeTwo,
  "Post meal slump": ChargeThree,
  "Tired, experiencing fatigue": ChargeFour,
};

export const EnergyLevels = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>What about your energy levels?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([value, Icon]) => (
          <IconCard
            onClick={() => handleNextStep(value + 1)}
            img={<Icon />}
            title={value}
            key={value}
          />
        ))}
      </div>
    </div>
  );
};
