import styles from "./styles.module.scss";

export const Input = ({
  placeholder,
  endAdornment,
  value,
  onChange,
  maxLength,
  type,
  inputmode,
}) => {
  return (
    <div className={styles.input}>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        inputMode={inputmode}
      />
      {endAdornment}
    </div>
  );
};
