import React from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import CheckoutModal from "./CheckoutModal";
import { closeModal } from "../../redux/slices/modalSlice";

const ModalPortal = () => {
  const dispatch = useDispatch();

  const showModal = useSelector((state) => state.modal.showModal);

  const handleCloseModal = () => dispatch(closeModal());

  return ReactDOM.createPortal(
    showModal && <CheckoutModal handleCloseModal={handleCloseModal} />,
    document.body
  );
};

export default ModalPortal;
