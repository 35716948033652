import { ImageCard } from "../../../../../common";
import { DATA } from "./data";
import styles from "./styles.module.scss";

export const MainGoal = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>What is your main goal?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([title, img]) => (
          <ImageCard
            onClick={() => handleNextStep(title)}
            text={title}
            img={img}
            key={title}
          />
        ))}
      </div>
    </div>
  );
};
