import { axiosInstance } from "../index";
import authService from "./authService";


const patchProgress = async (dayOn) => {
  return axiosInstance
    .post("me/exercise-progress/patch-progress", { dayOn }, {
      headers: {
        Authorization: authService.getCurrentUser(),
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const exerciseProgress = async () => {
  return axiosInstance
    .get("me/exercise-progress", {
      headers: {
        Authorization: authService.getCurrentUser(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const getAllExercises = async () => {
  return axiosInstance
    .get("exercises", {
      headers: {
        Authorization: authService.getCurrentUser(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const exercisesService = {
  patchProgress,
  exerciseProgress,
  getAllExercises,
};

export default exercisesService;
