import React, { useRef } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Steps } from "./data/steps";
import styles from "./styles.module.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import './fade.css';

export const CurrentStep = () => {
  const { title } = useParams();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const nodeRef = useRef();

  const currentStepIndex = params.get("q");

  const currentGroupIndex = Steps.findIndex((steps) => steps.title === title);
  const currentGroup = Steps[currentGroupIndex];
  const Component = currentGroup.steps[currentStepIndex - 1];

  const handleNextStep = () => {
    const nextStepIndex = +currentStepIndex + 1;

    if (
      currentGroupIndex + 1 === Steps.length &&
      +currentStepIndex === currentGroup.steps.length
    ) {
      navigate("/predicted");
      return;
    }

    if (nextStepIndex > currentGroup.steps.length) {
      navigate(`/onboarding/${Steps[currentGroupIndex + 1].title}?q=${1}`);
      return;
    }

    setParams({ q: nextStepIndex });
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={currentStepIndex + title}
        in={true}
        nodeRef={nodeRef}
        timeout={700}
        classNames="fade-in"
      >
        <div ref={nodeRef} className={styles.step}>
          <Component onNextStep={handleNextStep} />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};
