import { Checkbox, CheckboxChecked } from "../../assets/Checkbox";
import styles from "./styles.module.scss";

export const IconCardCheckbox = ({
  img,
  title,
  onClick,
  paragraph,
  checked,
}) => {
  return (
    <div
      className={
        checked ? `${styles.card} ${styles.checked}` : `${styles.card}`
      }
      onClick={onClick}
    >
      <div className={styles.content}>
        <div className={styles.icon}>{img}</div>
        <div className={styles.text}>
          {title}
          {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
        </div>
      </div>
      <div className={styles.checkbox}>
        {checked ? <CheckboxChecked /> : <Checkbox />}
      </div>
    </div>
  );
};
