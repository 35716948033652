import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

console.log(API_URL);

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.data?.code === "AUTHORIZATION_TOKEN_CORRUPTED") {
      localStorage.removeItem("FY-AuthToken");
      window.location = "/auth";
    }
    if (error?.response?.data?.code === "USER_HAS_NO_ACTIVE_SUBSCRIPTION") {
      localStorage.removeItem("FY-AuthToken");
      window.location = "/auth";
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.code === "ERR_NETWORK") {
      window.location = "/";
    }

    return Promise.reject(error);
  }
);

export { axiosInstance };
