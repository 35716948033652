import { IconCard } from "../../../../../common/iconCard";
import styles from "./styles.module.scss";
import {
  Cross,
  AthleticsTeamRunning,
  Furniture,
} from "../../../../../assets/Icons";

const DATA = {
  "I sit the majority of the day": Furniture,
  "I try to be active on breaks": AthleticsTeamRunning,
  "I'm on my feet all day long": Cross,
};

export const UsualDay = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>How would you describe your usual day?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([value, Icon]) => (
          <IconCard
            onClick={() => handleNextStep(value + 1)}
            img={<Icon />}
            title={value}
            key={value}
          />
        ))}
      </div>
    </div>
  );
};
