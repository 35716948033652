import { Button } from "../../../../../common";
import styles from "./styles.module.scss";
import slimdown from "./img/slimdown.jpg";

export const SlimDown = ({ onNextStep }) => {
  return (
    <div id="breakdown" className={styles.container}>
      <div className={styles.content}>
        <h2>
          Slim down effortlessly <br />
          with EasyFit
        </h2>
        <img src={slimdown} alt="slimdown" className={styles.image} />
        <div className={styles.hints}>
          No more exhausting, lengthy workouts or annoying diet restrictions!
          <br />
          <br />
          We're here to help you tone up and look fit in{" "}
          <strong>just 15-min a day.</strong>
        </div>
      </div>
      <Button onClick={onNextStep}>Continue</Button>
    </div>
  );
};
