import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { getInitialUserData } from "../redux/slices";
import authService from "./services/authService";

const ProtectedRoutes = () => {
  const [currentUser, setCurrentUser] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    const user = authService.getCurrentUser();

    setCurrentUser(!!user);

    if (user) {
      dispatch(getInitialUserData());
    }
  }, [dispatch]);

  if (currentUser === undefined) {
    return null;
  }

  return currentUser ? <Outlet /> : <Navigate to="/auth" replace />;
};

export default ProtectedRoutes;
