import {
  DesiredResult,
  FitnessLevel,
  FocusAreas,
  PeopleChosen,
  StartJourney,
  MainGoal,
  DreamBody,
  WeightFluctulate,
  SlimDown,
  PeakShape,
  BurnFat,
  Flexible,
  Breathing,
  IssuesStruggling,
  ReduceTension,
  ExcerciseFrequency,
  Walks,
  WorkLife,
  UsualDay,
  EnergyLevels,
  WaterIntake,
  Sleep,
  Breakfast,
  Lunch,
  Dinner,
  DietTypes,
  NutritionStatements,
  PastEvents,
  Height,
  Weight,
  WeightGoal,
  Age,
  Report,
  FutureEvent,
  EventDate,
} from "../questions";

export const Steps = [
  {
    title: "profile",
    steps: [
      StartJourney,
      PeopleChosen,
      DesiredResult,
      MainGoal,
      DreamBody,
      WeightFluctulate,
      SlimDown,
      PeakShape,
    ],
  },
  {
    title: "activity",
    steps: [
      FitnessLevel,
      FocusAreas,
      BurnFat,
      Flexible,
      Breathing,
      IssuesStruggling,
      ReduceTension,
      ExcerciseFrequency,
      Walks,
    ],
  },
  {
    title: "lifestyle",
    steps: [WorkLife, UsualDay, EnergyLevels, WaterIntake, Sleep],
  },
  {
    title: "nutrition",
    steps: [Breakfast, Lunch, Dinner, DietTypes, NutritionStatements],
  },
  {
    title: "almost_done",
    steps: [PastEvents, Height, Weight, WeightGoal, Age, Report, FutureEvent, EventDate],
  },
];
