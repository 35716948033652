import styles from "./styles.module.scss";

export const IconCard = ({ img, title, onClick, paragraph }) => {
  return (
    <div className={styles.card} onClick={onClick}>
      <div className={styles.icon}>{img}</div>
      <div className={styles.text}>
        {title}

        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
      </div>
    </div>
  );
};
