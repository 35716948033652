import { TermsWrapper } from "./TermsWrapper";
import classes from "./styles.module.scss";

export const TermsOfUse = () => {
  return (
    <TermsWrapper
      title="Terms of Use"
      lastUpdated={new Date("03-07-2024").toLocaleDateString()}
    >
      <div className={classes.item}>
        <h2>1. General</h2>
        <p>
          Please read the following Terms of Use together with the Privacy
          Policy carefully before your use of the EasyFit mobile application
          (the “Application”) and related services provided by Straiton Limited
          (“Us”, “We”). By downloading and using the Application you agree to be
          bound by these terms of service.
        </p>
      </div>

      <div className={classes.item}>
        <h2>2. Intellectual Property Rights</h2>
        <p>
          The Application and all related trademarks, logos, software and other
          intellectual property rights are and shall remain our exclusive
          property. Your right to use the Application and its content is
          personal, non-exclusive, non-sublicenseable and non-transferable. Any
          copying, republication or redistribution of the Application or any of
          its content is strictly prohibited.
        </p>
      </div>
      <div className={classes.item}>
        <h2>3. Limitation of Liability</h2>
        <p>
          We shall not be liable for any errors in any content or for any
          actions taken in reliance thereon. Furthermore, we shall not be liable
          to you for any interception of online communications, software or
          hardware problems (including, without limitation, viruses, loss of
          data or compatibility conflicts).
        </p>
      </div>
      <div className={classes.item}>
        <h2>4. Changes to this terms of service</h2>
        <p>
          We reserve the right to, from time to time, in our sole discretion
          amend these Terms of Use. It is your responsibility to review these
          Terms of Use from time to time to understand the extent of changes.
          Your continued use of the Application constitutes your agreement to
          the amended and updated Terms of Use.
        </p>
      </div>
      <div className={classes.item}>
        <h2>5. Subscription Information</h2>
        <p>
          EasyFit offers an auto-renewing subscription to provide you with
          unlimited access to the Premium content. Your payment will be charged
          to your iTunes Account as soon as you confirm your purchase. You can
          manage your subscriptions and turn off auto-renewal from your Account
          Settings after the purchase. Your subscription will renew
          automatically, unless you turn off auto-renew at least 24 hours before
          the end of the current period. The cost of renewal will be charged to
          your account in the 24 hours prior to the end of the current period.
          When canceling a subscription, your subscription will stay active
          until the end of the period. Auto-renewal will be disabled, but the
          current subscription will not be refunded. Any unused portion of a
          free trial period, if offered, will be forfeited when purchasing a
          subscription.
        </p>
      </div>
      <div className={classes.item}>
        <h2>6. Users Generated Content</h2>
        <p>
          On certain areas of our Platform or otherwise, you may be able to
          submit information and other content (“User Generated Content” or
          “UGC”). Your posting of UGC is subject to the Terms and the following
          posting rules (“Posting Rules”):
        </p>
        <p className={classes.marginLeft}>
          A. You agree that your UGC is:
          <ul>
            <li>Accurate; Not confidential;</li>
            <li>
              Not in violation of law; Not in violation of contractual
              restrictions or third party rights, and that you have permission
              to use content from any other party whose personal or other
              information or intellectual property is contained in the UGC;
            </li>{" "}
            <li>
              Not abusive, harmful, libelous, profane, obscene or otherwise
              objectionable;
            </li>
            <li>
              Not for commercial purposes or business solicitations; and Free of
              viruses, corrupting files, interference, cheat software, worms or
              other malicious code.
            </li>
          </ul>
        </p>
        <p className={classes.marginLeft}>
          B.  Responsibility of Postings.  You understand and acknowledge that
          UGC is solely your responsibility, and that EasyFit is not responsible
          for the information, data, text or other materials that may appear in
          UGC. User opinions expressed do not necessarily reflect the opinions
          of EasyFit. UGC is not necessarily reviewed by EasyFit prior to
          posting and EasyFit makes no warranties, express or implied, as to the
          UGC or to the accuracy and reliability of the UGC.
        </p>
        <p className={classes.marginLeft}>
          C.  No Monitoring/Violation Notices.  You acknowledge that EasyFit
          does not necessarily monitor any materials posted, transmitted, or
          communicated to or within the Platform. Notwithstanding the foregoing,
          you further agree that EasyFit and its designees will have the right
          (but not the obligation) in their sole discretion to refuse, edit,
          move, or remove any UGC that is available via the Platform.
        </p>
        <p>
          <strong>
            YOU UNDERSTAND AND AGREE THAT EASYFIT MAY DELETE ALL UGC AT ANY
            TIME, AND WITHOUT NOTICE, IF EASYFIT DEEMS THAT YOU MATERIALLY
            BREACH THESE POSTING RULES, THE TERMS, APPLICABLE LAW, OR FOR ANY
            OTHER REASON. EASYFIT ASSUMES NO LIABILITY FOR ANY INFORMATION
            REMOVED FROM OUR PLATFORM AND RESERVES THE RIGHT TO PERMANENTLY
            RESTRICT ACCESS TO SERVICES.
          </strong>
        </p>
      </div>
      <div className={classes.item}>
        <h2>7. Ownership and License of UGC</h2>
        <p>
          After posting your UGC to the Platform, you continue to retain all
          ownership rights in such UGC, and you continue to have the right to
          use your UGC in any way you choose, subject to the Terms and the
          license described herein (“UGC License”).
        </p>
        <p className={classes.marginLeft}>
          A.  Scope of License.
          <br /> By displaying, publishing, or otherwise posting any UGC on or
          through the Platform, you hereby grant to EasyFit the non-exclusive,
          irrevocable, worldwide, royalty free license to edit, adapt, publish,
          reproduce, distribute, publicly display and use your UGC and any
          derivative works we may create from it, in any and all media (whether
          it exists now or in the future), for any purpose, in perpetuity,
          without any payment to you.
        </p>
        <p className={classes.marginLeft}>
          B. Representations.
          <br /> You represent and warrant that: (i) you solely own the UGC
          displayed, published or posted by you on the Platform or otherwise
          have the right to grant the license set forth herein, and (ii) the
          displaying, publishing or posting of your UGC does not violate
          infringe on the rights of any third party, including any copyright,
          trademark, patent, trade secret or other intellectual property right,
          or the privacy rights, publicity rights, contract rights or any other
          rights of any person. You agree to pay for all royalties, fees, and
          any other monies owing any person by reason of any UGC displayed,
          published or posted by you to the Platform. Except for your UGC, you
          may not edit, adapt, publish, reproduce, distribute, publicly display
          and use any UGC appearing on this Platform.
        </p>
      </div>
    </TermsWrapper>
  );
};
