import { Check, Warning } from "../../../../../../assets/Icons";
import styles from "./styles.module.scss";

const BMIHintsMap = {
  normal: {
    icon: <Check />,
    paragraph: "Great starting point to get in shape and improve health",
    title: "Healthy BMI",
  },
  overweight: {
    icon: <Warning />,
    paragraph: "Great starting point to get in shape and improve health",
    title: "Risk of high BMI",
  },
  obese: {
    icon: <Warning color="#FF6A6A" />,
    paragraph: "Great starting point to get in shape and improve health",
    title: "Risk of high BMI",
  },
};

export const BMIHint = ({ bmiType }) => {
  const BMIHintInfo = BMIHintsMap[bmiType];
  return (
    <div className={`${styles.card} ${styles[bmiType]}`}>
      <div className={styles.title}>
        {BMIHintInfo.icon} <h6>{BMIHintInfo.title}:</h6>
      </div>
      <div className={styles.paragraph}>{BMIHintInfo.paragraph}</div>
    </div>
  );
};
