import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import exercisesService from "../../api/services/exercisesService";

const initialState = {
  isLoading: true,
  isLoadingResetProgress: false,
  allExercieses: [],
  userDaysProgress: null,
  error: null,
  success: false,
};

const allExerciesesSlice = createSlice({
  name: "allExercieses",
  initialState,
  reducers: {
    logoutUser() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitialUserData.fulfilled, (state, { payload }) => {
      state.allExercieses = payload.days;
      state.userDaysProgress = payload.userDaysProgress + 1;
      state.isLoading = false;
    });
    builder.addCase(resetUserProgress.pending, (state) => {
      state.isLoadingResetProgress = true;
    });
    builder.addCase(resetUserProgress.fulfilled, (state, { payload }) => {
      if (payload === 200) {
        state.userDaysProgress = 1;
      }
      state.isLoadingResetProgress = false;
    });
    builder.addCase(patchUserProgress.fulfilled, (state, { payload }) => {
      if (payload === 200) {
        state.userDaysProgress = state.userDaysProgress + 1;
      }
    });
  },
});

export const patchUserProgress = createAsyncThunk(
  "patchUserProgress",
  async (_, { getState }) => {
    const state = getState();

    if (state.slices.userDaysProgress > 27) {
      return;
    }

    const patchProgressStatus = await exercisesService
      .patchProgress(state.slices.userDaysProgress)
      .then((response) => response.status)
      .catch((error) => error);

    return patchProgressStatus;
  }
);

export const resetUserProgress = createAsyncThunk(
  "resetUserProgress",
  async (userProgress) => {
    const resetUserProgressStatus = await exercisesService
      .patchProgress(userProgress)
      .then((response) => response.status)
      .catch((error) => error);

    return resetUserProgressStatus;
  }
);

export const getInitialUserData = createAsyncThunk(
  "getInitialUserData",
  async () => {
    const allExercisesData = exercisesService.getAllExercises();
    const userProgressData = exercisesService.exerciseProgress();

    const [daysProgress, userDayOnProgress] = await Promise.all([
      allExercisesData,
      userProgressData,
    ])
      .then((response) => response)
      .catch((e) => {
        console.log(e);
      });

    return {
      days: daysProgress.days,
      userDaysProgress: userDayOnProgress.dayOn,
    };
  }
);

export const { logoutUser } = allExerciesesSlice.actions;

export default allExerciesesSlice.reducer;
