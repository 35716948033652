import { combineReducers, configureStore } from "@reduxjs/toolkit";
import slices from "./slices";

import { reducer as subscription } from "./slices/subscriptionSlice";
import { reducer as modal } from "./slices/modalSlice";


const reducer = combineReducers({
  slices,
  subscription,
  modal,
});

const store = configureStore({
  reducer,
});

export default store;
