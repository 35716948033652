import { TermsWrapper } from "./TermsWrapper";
import classes from "./styles.module.scss";

export const SubscriptionPolicy = () => {
  return (
    <TermsWrapper
      title="Subscription Policy"
      lastUpdated={new Date("03-07-2024").toLocaleDateString()}
    >
      <div className={classes.item}>
        <h2>Free or paid trial</h2>
        <p>
          We may offer a free or paid (for a small payment) trial subscription
          for the service. Unless you cancel before the end of the trial, you
          will be automatically charged a price indicated on the payment screen
          or/and Apple’s/Google’s payment pop-up screen for a chosen
          subscription period. Please note that if a trial is offered, this will
          be explicitly stated on the price screen before the checkout. If this
          is not the case, you will purchase our subscription without a trial.
          We may also from time to time offer discounted offers that renew at
          the full not discounted price and other offers that may be interesting
          for you.
        </p>
      </div>
      <div className={classes.item}>
        <h2>Subscription</h2>
        <p>
          The subscription renews automatically at the end of each period (each
          week, month, 6 months, year, or otherwise, depending on the option
          selected by you at the time of purchase) until you cancel.
        </p>
      </div>
      <div className={classes.item}>
        <h2>Payment method</h2>
        <p>
          Payment will be charged to the payment method you submitted at the
          time of purchase at confirmation of purchase (after you confirm by
          single-touch identification, facial recognition, or entering your
          payment method details on the web, or otherwise accepting subscription
          terms provided on the payment screen or on the pop-up screen provided
          by Apple/Google or on our web page) or after the end of the trial
          period. You authorize us to charge the applicable subscription fees to
          the payment method that you use.
        </p>
      </div>
      <div className={classes.item}>
        <h2>Cancellation</h2>
        <p>
          Your subscription renews automatically at the end of each period until
          you cancel. Note that deleting the app does not cancel your
          subscriptions.
        </p>
        <p>
          <ul>
            <li>
              If you purchased a subscription or enabled trial on the App Store:
              You may cancel a free trial or a subscription anytime by turning
              off auto-renewal through your Apple ID account settings. To avoid
              being charged, cancel the subscription in your Apple ID account
              settings at least 24 hours before the end of the free trial or the
              current subscription period. You alone can manage your
              subscriptions. Learn more about managing subscriptions (and how to
              cancel them) on Apple support.
            </li>
            <li>
              If you purchased a subscription or enabled trial on our websites:
              To avoid being charged, cancel your subscription before the end of
              the then-current period. You may cancel your subscription
              purchased on our website in your profile.
            </li>
          </ul>
        </p>
        <p>
          Canceling your subscription means that the automatic renewal will be
          disabled, but you will still have access to all your subscription
          features for the remaining time of your then-current period.
        </p>
      </div>
      <div className={classes.item}>
        <h2>Refunds</h2>
        <p>
          If you purchased a subscription or enabled trial on App Store: If you
          are eligible for a refund, you'll have to request it directly from
          Apple. To request a refund, follow these instructions from the Apple
          support page.
        </p>
        <p>
          If you purchased a subscription or enabled trial on our websites: We
          provide refunds at our own discretion and subject to laws and our
          policies that may be published from time to time. Refunds will be
          provided if we find the request acceptable.
        </p>
        <p>
          Please note that after your subscription period expires, we will not
          be able to refund you as the service will be deemed consumed in full,
          unless otherwise provided for by applicable law.
        </p>
      </div>
      <div className={classes.item}>
        <h2>Changes</h2>
        <p>
          To the maximum extent permitted by applicable laws, we may change
          subscription fees at any time. We will give you reasonable notice of
          any such pricing changes by posting the new prices on or through the
          app and/or by sending you an email notification, or in other prominent
          ways. If you do not wish to pay the new fees, you can cancel the
          applicable subscription prior to the change going into effect.
        </p>
        <p>
          If you have any questions in relation to the subscription terms,
          please contact us at{" "}
          <a href="mailto:apps@straitonapps.com">apps@straitonapps.com</a>.
        </p>
      </div>
    </TermsWrapper>
  );
};
