import { TermsWrapper } from "./TermsWrapper";
import classes from "./styles.module.scss";

export const PrivacyPolicy = () => {
  return (
    <TermsWrapper
      title="Privacy Policy"
      lastUpdated={new Date("03-07-2024").toLocaleDateString()}
    >
      <p>
        Welcome to read our Privacy Policy about EasyFit (“we”, “us”, “our”).
        The Privacy Policy is here to give you all the information you need
        about what kind of information we collect, how we use it, how we share
        it, how you control your data and make sure you know your rights in
        connection to when you use our applications, software, websites, APIs
        and services. By using or accessing our App or our Service, you agree to
        our Privacy Policy.
      </p>
      <div className={classes.item}>
        <h2>1. Information we collect</h2>
        <p>
          <strong>1.1 Contact Information</strong>
        </p>
        <p>
          If you reach out to us via email to get help or ask a question, we
          will respond to the contact information that you provide. The contact
          information will be used explicitly to communicate with you based on
          your question and request.
        </p>
        <p>
          <strong>1.2 Technical Information</strong>
        </p>
        <p>
          When you access EasyFit, we collect technical information from the
          devices you use. The information we collect includes, but is not
          limited to IP addresses, log files, device type, and operating system.
          We use the information we collect to improve the Services and to
          develop new features. For example, we use the information to
          troubleshoot and protect against errors; perform data analysis and
          testing; conduct research and surveys, and develop new features and
          services.
        </p>
        <p>
          <strong>1.3 Analytics Information</strong>
        </p>
        <p>
          We work with partners who provide us with analytics services. This
          includes helping us understand how users interact with the EasyFit
          services, the stability, and performance of the services. These
          companies may use device-specific advertiser identifiers, cookies, and
          similar technologies to collect information about your interactions
          with the Services and other websites and applications.
        </p>
      </div>
      <div className={classes.item}>
        <h2>2. How we use information</h2>
        <p>
          <strong>2.1 Improve, personalize, and develop the services</strong>
        </p>
        <p>
          We use the information we collect to improve the Services and to
          develop new features. We need this information to understand how users
          interact with EasyFit services, the stability, and performance of the
          services, etc. Health data is processed based on your consent.
        </p>
        <p>
          <strong>2.2 Communicate with you</strong>
        </p>
        <p>
          We use your information when needed to send you Service notifications
          and respond to you when you contact us. We guarantee that the
          information we collect will never be sold.
        </p>
      </div>
      <div className={classes.item}>
        <h2>3. Sharing of Information</h2>
        <p>
          We may share non-personal information that is aggregated or
          de-identified so that it cannot reasonably be used to identify an
          individual. Meanwhile, We may preserve or disclose information about
          you to comply with a law, regulation, legal process, or governmental
          request; to assert legal rights or defend against legal claims; or to
          prevent, detect, or investigate illegal activity, fraud, abuse,
          violations of our terms, or threats to the security of the Services or
          the physical safety of any person.
        </p>
        <p>
          Use for legal or regulation purposes is, by definition, compatible
          with the original purpose and no separate legal basis is required.
        </p>
      </div>
      <div className={classes.item}>
        <h2>4. Your Rights</h2>
        <p>
          You can simply uninstall the app in order to remove any stored data at
          any time. If you need any further assistance regarding your rights,
          feel free to contact us at{" "}
          <a href="mailto:apps@straitonapps.com">apps@straitonapps.com</a>.
        </p>
      </div>
      <div className={classes.item}>
        <h2>5. Our Policies for Children</h2>
        <p>
          We appreciate the importance of taking additional measures to protect
          children's privacy. We do not knowingly collect Personal Data from
          children under the age of 13.
        </p>
      </div>
      <div className={classes.item}>
        <h2>6. Changes to Privacy Policy</h2>
        <p>
          We reserve the right to change this Privacy Policy from time to time.
          We will inform you of any changes by posting the updated Privacy
          Policy through the mobile applications. If we make any material
          changes to our Privacy Policy, we will post a notification through the
          mobile applications and/or by asking you to review the changes to the
          Privacy Policy the next time you access the Services. Your continued
          use of the Services after the changes are made will indicate your
          consent to our use of your information consistent with this Privacy
          Policy, including those changes.
        </p>
      </div>
      <div className={classes.item}>
        <h2>7. Supplementary Clause</h2>
        <p>
          This Privacy Policy also applies to users in California under the
          California Consumer Privacy Act of 2018 ("CCPA"). We guarantee that
          the information we collect will never be sold. In addition, your
          rights under the CCPA:
        </p>
        <p>
          <strong>7.1  To know what information we collect.</strong>
        </p>
        <p>
          You can request the details of the information we have collected from
          you by contacting us at{" "}
          <a href="mailto:apps@straitonapps.com">apps@straitonapps.com</a>.
        </p>
        <p>
          <strong>7.2  To delete the information we collect.</strong>
        </p>
        <p>
          You can request the deletion of your data by contacting us at{" "}
          <a href="mailto:apps@straitonapps.com">apps@straitonapps.com</a>.
          After that, you can use our application as usual, without any
          discrimination.
        </p>
      </div>
    </TermsWrapper>
  );
};
