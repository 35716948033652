import { Link } from "react-router-dom";
import classes from "./styles.module.scss";
import home from "../../assets/home.svg";
import right from "../../assets/keyboardRight.svg";

export const TermsWrapper = ({ title, lastUpdated, children }) => {
  return (
    <div>
      <header className={classes.header}>
        <h1>{title}</h1>
      </header>
      <main className={classes.container}>
        <div className={classes.breadcrumbs}>
          <Link to="/">
            <img src={home} alt="home" />
          </Link>
          <img src={right} alt="right" />
          <div className={classes.breadcrumbsTitle}>{title}</div>
        </div>
        <div className={classes.title}>
          <h2>{title}</h2>
          <p>Last updated: {lastUpdated}</p>
        </div>
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  );
};
