import thin from "../img/thin.png";
import lean from "../img/lean.png";
import athletic from "../img/athletic.png";
import balanced from "../img/balanced.png";

export const DATA = {
  Thin: thin,
  Lean: lean,
  Athletic: athletic,
  Balanced: balanced,
};
