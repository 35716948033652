import { Link } from "react-router-dom";
import { Button, Input } from "../../../../../common";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Lock } from "../../../../../assets/Icons";

export const EventDate = ({ onNextStep }) => {
  const [date, setDate] = useState();

  const handleChange = (e) => {
    const value = e.currentTarget.value;
    setDate(value);
  };

  const handleNextStep = () => {
    console.log(date);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>When is this occasion?</h2>
        <p className={styles.subtitle}>
          We will keep track of this event for you
        </p>
        <div className={styles.input}>
          <Input type="date" value={date} onChange={handleChange} />
          <p className={styles.hint}>
            <Lock /> Your data will be processed in accordance with our{" "}
            <Link to="/privacy-policy"> Privacy Policy</Link>
          </p>
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!date}>
          Next Step
        </Button>
        <button className={styles.skip} onClick={onNextStep}>
          Skip this step
        </button>
      </div>
    </div>
  );
};
