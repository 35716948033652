import { useEffect } from "react";

export const usePreloadImages = (images) => {
  useEffect(() => {
    images.forEach((item) => {
      const img = new Image();
      img.src = item;
    });
  }, []);
};
