import { DATA } from "./data";
import styles from "./styles.module.scss";

export const Breathing = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };
  return (
    <div className={styles.container}>
      <h2>Can you maintain steady breathing when taking the stairs?</h2>
      <div className={styles.cards}>
        {DATA.map((item, index) => (
          <div
            onClick={() => handleNextStep(index)}
            key={item}
            className={styles.card}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
