import classes from "./styles.module.scss";

const SubmitButton = ({
  title,
  isLoading,
  variant,
  type,
  onClick = () => {},
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={
        isLoading
          ? `${classes.submitButton} ${classes.submitButtonLoading} ${classes[variant]}`
          : `${classes.submitButton} ${classes[variant]}`
      }
    >
      {isLoading ? <div className={classes.dotFlashing} /> : title}
    </button>
  );
};

export default SubmitButton;
