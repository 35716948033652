import classes from "./styles.module.scss";
import logo from "../../assets/img/png/logo_EasyFit.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const LandingPage = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const toggleBurger = () => {
    setOpen(!open);
  };

  const openQuestionnaire = () => {
    navigate('/onboarding/profile?q=1')
  };
  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <div className={classes.content}>
          <img src={logo} alt="Logo" />
          <nav
            className={
              open
                ? `${classes.navigation}  ${classes.open}`
                : `${classes.navigation} ${classes.close}`
            }
          >
            <ul>
              <li>
                <Link to="/terms-of-use">Terms of Use</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/subscription-policy">Subscription Policy</Link>
              </li>
            </ul>
          </nav>
          <div
            className={
              open ? `${classes.burger} ${classes.open}` : `${classes.burger}`
            }
            onClick={toggleBurger}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </header>
      <main className={classes.main}>
        <div className={classes.title}>
          <h1>
            Easy & Effective Workouts: custom exercise plans for any fitness
            goal. Start now
          </h1>
          <button onClick={openQuestionnaire} className={classes.cta}>
            Get Started
          </button>
        </div>
      </main>
    </div>
  );
};
