// Assets
import wrong from "../../assets/wrong.png";

import classes from "./styles.module.scss";

const SomethingWentWrong = () => {
  return (
    <div className={classes.body}>
      <img src={wrong} alt="wrong" />
      <p className={classes.mainTitle}>Oops...</p>
      <p className={classes.subTitle}>Sorry, something went wrong.</p>
      <p className={classes.descriptionFirst}>
        We apologies for this problem and are working hard on it.
      </p>
      <p className={classes.descriptionSecond}>
        We will get it fixed as soon as possible.
      </p>
    </div>
  );
};

export default SomethingWentWrong;
