// Libs
import { useEffect, useMemo, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

// Components
import BottomSheetContainer from "./BottomSheet";
import ProfileSettings from "./ProfileSettings";
import Advices from "./Advices";

// Assets
import personCircle from "../../assets/PersonCircle.svg";
import startButton from "../../assets/startButton.svg";

// Utils
import getUserCurrentProgress from "../../utils/getUserCurrentProgress";

import classes from "./styles.module.scss";
import "react-circular-progressbar/dist/styles.css";

const ProfilePage = () => {
  const navigate = useNavigate();

  const { allExercieses, userDaysProgress, isLoading } = useSelector(
    (state) => state.slices
  );

  const [activeDayIndex, setActiveDayIndex] = useState();
  const [activeDayExercises, setActiveDayExercises] = useState([]);
  const [activeDayExercise, setActiveDayExercise] = useState(0);

  const [openSettingBottomSheet, setOpenSettingBottomSheet] = useState(false);
  const [openAdvicesBottomSheet, setOpenAdvicesBottomSheet] = useState(false);

  useEffect(() => {
    setActiveDayIndex(userDaysProgress);
  }, [userDaysProgress]);

  useEffect(() => {
    setActiveDayExercises(allExercieses[userDaysProgress - 1]?.exercises);
  }, [userDaysProgress, allExercieses]);

  return useMemo(
    () => (
      <div className={classes.container}>
        <header className={classes.header}>
          <p>Face massage</p>
          <img
            src={personCircle}
            alt="profile"
            onClick={() => setOpenSettingBottomSheet(true)}
          />
        </header>
        <main className={classes.main}>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <div className={classes.progressBarWrapper}>
                <CircularProgressbar
                  strokeWidth={3}
                  styles={{
                    trail: {
                      color: "#ff5a7f",
                      stroke: "#fff",
                    },
                    root: {
                      borderRadius: "50%",
                      backgroundColor: "rgba(124, 138, 247, 1)",
                    },
                    path: {
                      stroke: "#FFFFFF",
                      color: "#FFFFFF",
                    },
                    text: {
                      fontWeight: "700",
                      fill: "white",
                      fontSize: "18px",
                    },
                  }}
                  value={getUserCurrentProgress(userDaysProgress)}
                  text={`${getUserCurrentProgress(userDaysProgress)}%`}
                />
              </div>
              <p className={classes.title}>Your current progress</p>
              <p className={classes.subtitle}>
                According to your personal plan
              </p>
              <button
                className={classes.startButton}
                onClick={() => {
                  navigate(`/day-exercises/${activeDayIndex}`);
                }}
              >
                <img src={startButton} alt="" />
                <p>Start day {activeDayIndex}</p>
              </button>
              <div className={classes.daysWrapper}>
                {allExercieses.map(({ exercises }, index) => {
                  return userDaysProgress > index ? (
                    <div
                      onClick={() => {
                        setActiveDayExercises(exercises);
                        setActiveDayIndex(index + 1);
                      }}
                      key={index}
                      className={
                        index + 1 === activeDayIndex
                          ? classes.dayItemActive
                          : classes.dayItemComplete
                      }
                    >
                      {`Day ${index + 1}`}
                    </div>
                  ) : (
                    <div key={index} className={classes.dayItem}>
                      {`Day ${index + 1}`}
                    </div>
                  );
                })}
              </div>
              <div className={classes.contentWrapper}>
                {activeDayExercises?.map((exercise) => {
                  return (
                    <div
                      key={Math.random()}
                      className={classes.contentItem}
                      onClick={() => {
                        setActiveDayExercise(exercise);
                        setOpenAdvicesBottomSheet(true);
                      }}
                    >
                      <img src={exercise.preview} alt="preview" />
                      <div className={classes.infoWrapper}>
                        <p className={classes.contentTitle}>{exercise.title}</p>
                        <p className={classes.contentSubtitle}>
                          {exercise.subTitle}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <BottomSheetContainer
            open={openSettingBottomSheet}
            setOpen={setOpenSettingBottomSheet}
          >
            <ProfileSettings />
          </BottomSheetContainer>

          <BottomSheetContainer
            open={openAdvicesBottomSheet}
            setOpen={setOpenAdvicesBottomSheet}
          >
            <Advices {...activeDayExercise} />
          </BottomSheetContainer>
        </main>
      </div>
    ),
    [
      isLoading,
      userDaysProgress,
      activeDayIndex,
      allExercieses,
      activeDayExercises,
      openSettingBottomSheet,
      openAdvicesBottomSheet,
      activeDayExercise,
      navigate,
    ]
  );
};

export default ProfilePage;
