export const cmToFt = (cm) => {
  return +cm / 30.48;
};

export const ftToCm = (ft) => {
  return Math.floor(+ft * 30.48);
};

export const ftToFtIn = (value) => {
  const ft = Math.abs(value);
  const inch = (ft - Math.floor(value)) * 12;
  return { ft: Math.floor(ft), inch };
};

export const kgToLbs = (kg) => {
  return +kg * 2.205;
};

export const lbsToKg = (lbs) => {
  return +lbs / 2.205;
};
