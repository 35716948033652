// Libs
import { useCallback, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { v4 as uuid } from "uuid";

// Api
import { patchUserProgress } from "../../redux/slices";

// Assets
import nextIcon from "../../assets/nextIcon.svg";

import classes from "./styles.module.scss";
import "bootstrap/dist/css/bootstrap.css";
import "./videoCarousel.css";

const DayExercises = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeCarouselSlideIndex, setActiveCarouselSlideIndex] = useState(0);

  const handleSelect = useCallback((selectedIndex) => {
    setActiveCarouselSlideIndex(selectedIndex);
  }, []);

  const { allExercieses, isLoading } = useSelector((state) => state.slices);

  const currentDay = useMemo(
    () => allExercieses.find((_, index) => index + 1 === Number(id)),
    [allExercieses, id]
  );

  const currentDayExercise = useMemo(
    () =>
      currentDay?.exercises.find(
        (_, index) => index === activeCarouselSlideIndex
      ),
    [activeCarouselSlideIndex, currentDay?.exercises]
  );

  const nextDayExercise = useMemo(
    () =>
      currentDay?.exercises.find(
        (_, index) => index === activeCarouselSlideIndex + 1
      ),
    [activeCarouselSlideIndex, currentDay?.exercises]
  );

  const videosFromExercises = useMemo(
    () => currentDay?.exercises.map((exercise) => exercise.videos[0]),
    [currentDay?.exercises]
  );

  const isLastSlide = useMemo(
    () => currentDay?.exercises.length - 1 === activeCarouselSlideIndex,
    [activeCarouselSlideIndex, currentDay?.exercises.length]
  );

  const handleNextSlide = () => setActiveCarouselSlideIndex((prev) => prev + 1);

  return (
    <div className={classes.page}>
      <div className={classes.backToProfileButton}>
        <p
          onClick={() => {
            navigate("/profile");
          }}
          className={classes.backToProfileText}
        >
          END
        </p>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className={classes.carouselWrapper}>
            <div className={classes.carouselIndicatorsWrapper}>
              {videosFromExercises?.map((_, index) => (
                <div
                  key={uuid()}
                  className={
                    activeCarouselSlideIndex === index
                      ? `${classes.carouselIndicator} ${classes.carouselIndicatorActive} `
                      : classes.carouselIndicator
                  }
                  onClick={() => handleSelect(index)}
                />
              ))}
            </div>
            <ReactPlayer
              url={currentDayExercise?.videos[0]}
              width={"100%"}
              volume={1}
              loop={false}
              controls
              playing
              playsinline
            />
          </div>
          {!isLastSlide ? (
            <>
              <p className={classes.nextTitle}>Next</p>
              <div
                className={classes.nextVideoWrapper}
                onClick={() => handleNextSlide()}
              >
                <div className={classes.nextVideoContentWrapper}>
                  <img
                    className={classes.nextVideoImage}
                    src={nextDayExercise?.preview}
                    alt="next"
                  />
                  <div className={classes.nextVideoInfo}>
                    <p className={classes.nextVideoTitle}>
                      {nextDayExercise?.title}
                    </p>
                    <p className={classes.nextVideoSubtitle}>
                      {nextDayExercise?.subTitle}
                    </p>
                  </div>
                </div>
                <div>
                  <img src={nextIcon} alt="next" />
                </div>
              </div>
            </>
          ) : (
            <>
              <p className={classes.nextTitle}>Last</p>
              <div
                className={classes.nextVideoWrapper}
                onClick={() => {
                  dispatch(patchUserProgress());
                  navigate("/profile");
                }}
              >
                <div className={classes.nextVideoContentWrapper}>
                  <div className={classes.nextVideoInfo}>
                    <p className={classes.nextVideoTitle}>End the day</p>
                  </div>
                </div>
                <div>
                  <img src={nextIcon} alt="next" />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DayExercises;
