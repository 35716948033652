import styles from "./styles.module.scss";
import sleep from "./img/sleep.jpg";

const DATA = [
  "Less than 5 hours",
  "5-6 hours",
  "7-8 hours",
  "More than 8 hours",
];

export const Sleep = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>How much sleep do you get every night?</h2>
      <img src={sleep} alt="sleep" className={styles.image} />
      <div className={styles.cards}>
        {DATA.map((item, index) => (
          <div
            onClick={() => handleNextStep(index + 1)}
            className={styles.card}
            key={item}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
