import mid from "../img/mid.png";
import plus from "../img/plus.png";
import slim from "../img/slim.png";
import over from "../img/overweight.png";

export const DATA = {
  Slim: slim,
  "Mid-sized": mid,
  "Plus-sized": plus,
  Overweight: over,
};
