import styles from "./styles.module.scss";
import breakfast from "./img/breakfast.jpg";

const DATA = [
  "Between 6 and 8 am",
  "Between 8 and 10 am",
  "Between 10 am and noon",
  "I usually skip breakfast",
];

export const Breakfast = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>When do you typically have breakfast?</h2>
      <img src={breakfast} alt="breakfast" className={styles.image} />
      <div className={styles.cards}>
        {DATA.map((item, index) => (
          <div
            onClick={() => handleNextStep(index + 1)}
            className={styles.card}
            key={item}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
