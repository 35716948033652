export const Devices = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0316 20.5835H3.25033C2.65233 20.5835 2.16699 20.0982 2.16699 19.5002V18.4168C2.16699 17.8188 2.65233 17.3335 3.25033 17.3335H15.0316"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6667 5.41667C21.6667 4.21958 20.6971 3.25 19.5 3.25H5.41667C4.21958 3.25 3.25 4.21958 3.25 5.41667V17.3333"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1979 8.6665H21.6667C22.8638 8.6665 23.8333 9.63609 23.8333 10.8332V20.5832C23.8333 21.7803 22.8638 22.7498 21.6667 22.7498H17.1979C16.0008 22.7498 15.0312 21.7803 15.0312 20.5832V10.8332C15.0312 9.63609 16.0008 8.6665 17.1979 8.6665Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6667 8.6665V5.4165"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4814 20.3127H20.3773"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Night = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6721 17.0625C14.324 17.0617 11.1745 15.474 9.18267 12.7829C7.19085 10.0918 6.59256 6.61579 7.56999 3.41357C3.1625 5.44513 0.707581 10.2197 1.61963 14.9864C2.53168 19.7531 6.57577 23.2841 11.4219 23.5451C16.2681 23.806 20.668 20.7298 22.0867 16.0886C20.7033 16.73 19.1969 17.0624 17.6721 17.0625V17.0625Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9844 4.0625H16.8594"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4219 1.625V6.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2969 8.9375H22.5469"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9219 7.3125V10.5625"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Paint = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9985 6.90684C12.8338 6.90684 12.7006 7.04009 12.7027 7.20475C12.7027 7.36942 12.836 7.50267 13.0007 7.50267C13.1653 7.50267 13.2986 7.36942 13.2986 7.20475C13.2964 7.04009 13.1642 6.90684 12.9985 6.90684"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50115 13C7.50115 12.8353 7.3679 12.702 7.20432 12.7042C7.03965 12.7042 6.9064 12.8375 6.9064 13.0021C6.9064 13.1668 7.03965 13.3 7.20432 13.3C7.36898 13.3 7.50115 13.1657 7.50115 13"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3084 8.69075C17.1925 8.57484 17.004 8.57484 16.8892 8.69184C16.7733 8.80775 16.7733 8.99625 16.8892 9.11217C17.0051 9.22809 17.1936 9.22809 17.3095 9.11217C17.4254 8.99517 17.4254 8.80775 17.3084 8.69075"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.11019 16.8875C8.99428 16.7716 8.80578 16.7716 8.69094 16.8886C8.57503 17.0045 8.57503 17.193 8.69094 17.3089C8.80686 17.4249 8.99536 17.4249 9.11128 17.3089C9.22719 17.193 9.22719 17.0045 9.11019 16.8875"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.11182 9.11068C9.22773 8.99476 9.22773 8.80626 9.11073 8.69143C8.99482 8.57551 8.80632 8.57551 8.6904 8.69143C8.57448 8.80735 8.57448 8.99585 8.6904 9.11176C8.80632 9.22768 8.99482 9.22768 9.11182 9.11068"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0005 22.7495C7.52424 22.7495 3.10207 18.2342 3.25482 12.7233C3.39565 7.63592 7.6369 3.39467 12.7242 3.25384C18.2352 3.10109 22.7505 7.52326 22.7505 12.9995V14.0828C22.7505 15.2799 21.7809 16.2495 20.5838 16.2495H18.3489C16.9092 16.2495 15.8702 17.6275 16.2657 19.0109L16.5441 19.987C16.9406 21.3715 15.9006 22.7495 14.4619 22.7495H13.0005Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Rest = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9997 16.25V9.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 9.75C9.75 6.175 11.1583 3.25 13 3.25C14.8417 3.25 16.25 6.175 16.25 9.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.69167 19.5L6.5 22.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2246 19.5L18.4163 22.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.875 19.5003H16.9C18.0917 19.5003 19.175 19.067 20.15 18.417L22.1 16.9003C22.6417 16.467 22.8583 15.7086 22.6417 15.0586V15.0586C22.3167 13.9753 21.0167 13.6503 20.15 14.3003L18.2 15.817C17.875 16.142 17.3333 16.2503 16.9 16.2503H4.875C4.00833 16.2503 3.25 17.0086 3.25 17.8753V17.8753C3.25 18.742 4.00833 19.5003 4.875 19.5003Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33301 9.75H21.6663C21.6663 6.5 17.333 3.25 12.9997 3.25C8.66634 3.25 4.33301 6.5 4.33301 9.75Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
