// Libs
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

// Components
import Modal from "../../common/Modal";
import SubmitButton from "../../common/SubmitButton";

// API
import authService from "../../api/services/authService";

import classes from "./styles.module.scss";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("E-mail is required!")
      .matches(
        /[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+/g,
        "Incorrect email. Please try again"
      ),
  })
  .required();

const ForgetPasswordModal = ({ toogleModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email }) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    await authService.sendPinToEmail(email, setError);
    setIsLoading(false);
  };

  return (
    <Modal toogleModal={toogleModal}>
      <p className={classes.modalTitle}>Forgot pin?</p>
      <p className={classes.modalDescription}>
        We will send to your email new pin
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.modalForm}>
        <input
          placeholder="Email"
          className={errors.email && classes.errorInput}
          {...register("email")}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <p className={classes.errorMessage}>{message}</p>
          )}
        />
        <SubmitButton
          type="submit"
          title="Send pin"
          isLoading={isLoading}
          variant="blackButton"
        />
      </form>
    </Modal>
  );
};

export default ForgetPasswordModal;
