import { IconCard } from "../../../../../common/iconCard";
import styles from "./styles.module.scss";
import weightLoss from "./img/weight-loss.svg";
import strength from "./img/strength.svg";
import yogaPose from "./img/yoga-pose.svg";
import meditation from "./img/meditation.svg";
import shoulders from "./img/shoulders-back.svg";
import { usePreloadImages } from "../../../../../hooks/usePreloadImages";
import { DATA as mainGoal } from "../mainGoal/data";
import { DATA as dreamBody } from "../dreamBody/data";

const DATA = {
  "Lose weight & tone up": weightLoss,
  "Build muscle": strength,
  "Become more flexible": yogaPose,
  "Enhance mental health": meditation,
  "Develop endurance": shoulders,
};

export const DesiredResult = ({ onNextStep }) => {
  usePreloadImages([...Object.values(mainGoal), ...Object.values(dreamBody)]);

  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>What is your desired result?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([value, icon]) => (
          <IconCard
            onClick={() => handleNextStep(value)}
            img={<img src={icon} alt={value} />}
            title={value}
            key={value}
          />
        ))}
      </div>
    </div>
  );
};
