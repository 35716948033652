import { Button, Input } from "../../../../../common";
import { kgToLbs } from "../../../../../utils/measurements";
import { GoalHint } from "./goalHint";
import styles from "./styles.module.scss";
import { useState } from "react";

const Measures = {
  us: "lbs",
  eu: "kg",
};

export const WeightGoal = ({ onNextStep }) => {
  const ms = localStorage.getItem("ms");
  const [weight, setWeight] = useState();
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    if (value === "") {
      setWeight("");
      setIsValid(false);
      return;
    }

    if (value.length <= 3 && !isNaN(value)) {
      const numericValue = Number(value);
      setWeight(numericValue);

      if (ms === "us") {
        setIsValid(numericValue <= 662 && numericValue >= 55);
      } else {
        setIsValid(numericValue <= 300 && numericValue >= 25);
      }
    }
  };
  const handleNextStep = () => {
    console.log(weight);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Great! And what's your weight goal?</h2>
        <div className={styles.input}>
          <Input
            inputmode="numeric"
            type="number"
            step="1"
            placeholder={"Weight goal"}
            value={weight}
            onChange={handleChange}
            endAdornment={<h5>{Measures[ms]}</h5>}
          />

          <p className={styles.hint}>
            Please, enter a value from{" "}
            <span>
              {ms === "eu"
                ? `${25} kg to ${300} kg`
                : `${kgToLbs(25).toFixed(0)} lbs to ${kgToLbs(300).toFixed(
                    0
                  )} lbs`}
            </span>
          </p>
        </div>
        {!!weight && <GoalHint />}
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!isValid}>
          Next Step
        </Button>
      </div>
    </div>
  );
};
