import { Button } from "../../../../../common";
import styles from "./styles.module.scss";
import reduce from "./img/reduce.jpg";

export const ReduceTension = ({ onNextStep }) => {
  return (
    <div id="breakdown" className={styles.container}>
      <div className={styles.content}>
        <h2>Reduce overall body tension</h2>
        <img src={reduce} alt="slimdown" className={styles.image} />
        <div className={styles.hints}>
          Learn to perform low-intensity, effective workouts and stretches to
          <strong>reduce back, neck and shoulder tension,</strong> while
          supporting joint mobility.
        </div>
      </div>
      <Button onClick={onNextStep}>Continue</Button>
    </div>
  );
};
