import { useState } from "react";
import { Button, IconCardCheckbox } from "../../../../../common";
import styles from "./styles.module.scss";
import {
  AnimalDrumsticks,
  CupcakeCherry,
  SeasiningSalt,
  SoftDrinksCanCoffee,
  WeatherNightClear,
} from "../../../../../assets/Icons";

const DATA = {
  "I eat late at night": WeatherNightClear,
  "I consume lots of sugar": CupcakeCherry,
  "I can't live without soda": SoftDrinksCanCoffee,
  "I consume lots of salt": SeasiningSalt,
  "None of the above": AnimalDrumsticks,
};

export const NutritionStatements = ({ onNextStep }) => {
  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }

      if (value === "None of the above") {
        return ["None of the above"];
      }

      return [
        ...prevState.filter((item) => item !== "None of the above"),
        value,
      ];
    });
  };

  const handleNextStep = () => {
    console.log(checked);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Do you agree with any of these statements?</h2>
        <p className={styles.hint}>Choose all that apply</p>
        <div className={styles.cards}>
          {Object.entries(DATA).map(([value, Icon]) => (
            <IconCardCheckbox
              checked={checked.includes(value)}
              onClick={() => handleCheck(value)}
              img={<Icon />}
              title={value}
              key={value}
            />
          ))}
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
