import styles from "./styles.module.scss";
import dinner from "./img/dinner.jpg";

const DATA = [
  "Between 4 and 6 pm",
  "Between 6 and 8 pm",
  "Between 8 and 10 pm",
  "I usually skip dinner",
];

export const Dinner = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <h2>What time do you have dinner?</h2>
      <img src={dinner} alt="breakfast" className={styles.image} />
      <div className={styles.cards}>
        {DATA.map((item, index) => (
          <div
            onClick={() => handleNextStep(index + 1)}
            className={styles.card}
            key={item}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
