import { Button, OnboardingLayout } from "../../../common";
import styles from "./styles.module.scss";
import { DATA } from "../stepper/questions/futureEvent";
import { ChartLoseWeight } from "./ChartLoseWeight";
import { ChartGainWeight } from "./ChartGainWeight";
import { useNavigate } from "react-router";

export const Prediction = () => {
  const navigate = useNavigate();
  const weight = 66;
  const goal = 77;
  const date = "01-01-2025";
  const event = "Vacation";
  const isLoseWeight = weight > goal;

  return (
    <OnboardingLayout>
      <div className={styles.container}>
        <h2>The only program you'll ever need to get your dream body</h2>
        <p className={styles.subtitle}>We predict you'll be</p>
        <p className={styles.predictedData}>
          {goal} lbs <span>by</span>{" "}
          {new Date(date).toLocaleString("en-US", {
            month: "long",
            day: "2-digit",
          })}
        </p>
        <div className={styles.event}>
          Just in time for the {event}{" "}
          <div className={styles.icon}>{DATA[event]()}</div>
        </div>
        <div className={styles.chart}>
          <div className={styles.axisx}>
            <span>Jun 3</span>
            <span>Jul 1</span>
          </div>
          <div className={styles.axisy}>
            <span>50</span>
            <span>51</span>
            <span>52</span>
          </div>
          <div
            className={
              isLoseWeight
                ? `${styles.label}`
                : `${styles.label} ${styles.gain}`
            }
          >
            Goal <br />
            {goal} {"lbs"}
          </div>
          {isLoseWeight ? <ChartLoseWeight /> : <ChartGainWeight />}
        </div>
        <div className={styles.hint}>
          *Established on the data provided by the users to the app. Check with
          your doctor first. This chart is just a visual representation of a
          possible fitness journey.
        </div>
        <Button onClick={() => navigate("/creating-plan")}>Continue</Button>
      </div>
    </OnboardingLayout>
  );
};
