import { Button } from "../../../../../common";
import styles from "./styles.module.scss";

export const PeopleChosen = ({ onNextStep }) => {
  
  return (
    <div className={styles.container} id="breakdown">
      <div>
        <h1>150 million people</h1>
        <p>have chosen EasyFit.</p>
      </div>
      <Button onClick={onNextStep}>Continue</Button>
    </div>
  );
};
