import {
  Fire,
  FitnessSlimWaist,
  FitnessWeights,
  Running,
} from "../../../../../assets/Icons";
import { Button } from "../../../../../common";
import { BMIHint } from "./BMIHint";
import BMIScale from "./BMIScale";
import styles from "./styles.module.scss";
import normal from "./img/normal.jpg";
import overweight from "./img/overweight.jpg";
import underweight from "./img/underweight.jpg";
import obese from "./img/obese.jpg";

const FAKE_RESPONSE = {
  bodyType: "Endomorph",
  lifeStyle: "Active",
  fitnessLevel: "Advanced",
  metabolism: "Slow, easy to gain weight",
};

const BMI_TYPE_IMAGES = {
  normal,
  overweight,
  obese,
  underweight,
};

export const Report = ({ onNextStep }) => {
  const index = 38;

  let bmiType = "normal";

  if (index <= 18.5) {
    bmiType = "underweight";
  } else if (index >= 26 && index <= 30) {
    bmiType = "overweight";
  } else if (index >= 31) {
    bmiType = "obese";
  }
  return (
    <div className={styles.container}>
      <h2>Here's your fitness report</h2>
      <div className={styles.content}>
        <h3>Body Mass Index (BMI)</h3>
        <BMIScale bmi={index} />
        {bmiType !== "underweight" && (
          <div className={styles.hint}>
            <BMIHint bmiType={bmiType} />
          </div>
        )}
        <div className={styles.summary}>
          <div className={styles.summaryItems}>
            <div className={styles.summaryItem}>
              <div className={styles.icon}>
                <FitnessSlimWaist />
              </div>
              <div className={styles.text}>
                <p>Body Type</p>
                <h5>{FAKE_RESPONSE.bodyType}</h5>
              </div>
            </div>
            <div className={styles.summaryItem}>
              <div className={styles.icon}>
                <Running />
              </div>
              <div className={styles.text}>
                <p>Lifestyle</p>
                <h5>{FAKE_RESPONSE.lifeStyle}</h5>
              </div>
            </div>
            <div className={styles.summaryItem}>
              <div className={styles.icon}>
                <FitnessWeights />
              </div>
              <div className={styles.text}>
                <p>Fitness level</p>
                <h5>{FAKE_RESPONSE.fitnessLevel}</h5>
              </div>
            </div>
            <div className={styles.summaryItem}>
              <div className={styles.icon}>
                <Fire />
              </div>
              <div className={styles.text}>
                <p>Metabolism</p>
                <h5>{FAKE_RESPONSE.metabolism}</h5>
              </div>
            </div>
          </div>
          <div className={styles.image}>
            <img src={BMI_TYPE_IMAGES[bmiType]} alt={bmiType} />
          </div>
        </div>
      </div>
      <Button onClick={onNextStep}>Continue</Button>
    </div>
  );
};
