// Libs
import ReactPlayer from "react-player";
import { v4 as uuid } from "uuid";

import classes from "./styles.module.scss";

const Advices = ({ title, steps, videos }) => {
  return (
    <div className={classes.advicesContainer}>
      <ReactPlayer
        url={videos[0]}
        width={291}
        height={291}
        volume={1}
        loop={false}
        controls
        playing
        muted
        playsinline
      />
      <p className={classes.adviceTitle}>{title}</p>
      <div className={classes.advicesWrapper}>
        {steps.map((name, index) => (
          <div key={uuid()} className={classes.adviceItem}>
            <div className={classes.adviceIndex}>{index + 1}</div>
            <p className={classes.adviceName}>{name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advices;
